:root {
    --primary-font-color-0: rgb(20, 20, 20);
    --primary-font-color-1: rgb(35, 35, 35);
    --primary-font-color-2: rgb(55, 55, 55);
    --primary-font-color-3: rgb(75, 75, 75);
    --primary-font-color-4: rgb(95, 95, 95);
    --primary-font-color-5: rgb(125, 125, 125);
    --primary-font-color-6: rgb(145, 145, 145);
    --primary-font-color-7: rgb(165, 165, 165);
    --primary-font-color-8: rgb(190, 190, 190);
    --primary-font-color-9: rgb(220, 220, 220);
    --primary-font-color-10: rgb(240, 240, 240);
    --display-font: 'Inter', sans-serif;
    --text-font: 'Lato', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img, label {
    user-select: none;
    -webkit-user-select: none;
}

body {
    position: relative;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

h1 {
    font-family: var(--display-font);
    font-size: clamp(40px, 10vw, 90px);
    font-weight: 600;
    letter-spacing: -2px;
}

h4 {
    font-size: 16px;
    font-weight: 500;
}

p {
    font-size: 13px;
    font-weight: 400;
}

input[type=search],
input[type=text],
input[type=email],
input[type=password],
input[type=address] {
    outline: none;
    border: var(--primary-font-color-7) 1.5px solid;
    border-radius: 5px;
    padding: 8px 12px 8px 36px;
    font-size: 15px;
    color: var(--primary-font-color-1);
    transition: ease 300ms;
}

input[type=search]:focus,
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=address]:focus {
    border: var(--primary-font-color-3) 1.5px solid;
}

::-webkit-outer-spin-button,
::-webkit-inner-spin-button,
::-webkit-search-cancel-button {
    display: none;
}

.btn-group {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button {
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 300ms ease;
}

.primary-btn {
    background-color: var(--primary-font-color-0);
    color: rgb(255, 255, 255);
    box-shadow: 0px 5px 10px rgba(20, 20, 20, 0.2);
    border: var(--primary-font-color-0) 1px solid;
}

.primary-btn:hover {
    background-color: var(--primary-font-color-1);
}

.secondary-btn {
    background-color: transparent;
    color: var(--primary-font-color-2);
    border: var(--primary-font-color-7) 1px solid;
}

.secondary-btn:hover {
    color: var(--primary-font-color-0);
    border-color: var(--primary-font-color-5);
}

button, .m-btn {
    padding: 6px 12px;
    font-size: 13px;
    border-radius: 3px;
    border-width: 1px;
}

.s-btn {
    padding: 4px 8px;
    font-size: 11.5px;
}

.l-btn {
    padding: 12px 25px;
    font-size: 20px;
    border-radius: 6px;
    border-width: 2px;
}

.l-btn.primary-btn:hover {
    border: var(--primary-font-color-1) 2px solid;
}

.page-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 50px 0;
}

.page-container > section {
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 20px 40px;
}

.flex-center {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-container {    
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.page-container > section > .flex-container > .hero-img {
    flex: 1;
    margin: 0 20px;
    max-width: 100%;
}

.page-container > section > .flex-container > h1 {
    color: var(--primary-font-color-6);
    line-height: 110%;
    flex: 1;
}

.page-container > section > .btn-group {
    width: clamp(200px, 100%, 400px);
    margin: 0 auto;
}

/*Item*/

.item-grid {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
    grid-gap: 1.2rem;
    padding: 0 10px;
    margin: 40px 0;
}

.item {
    position: relative;
    width: auto;
    margin: 0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(20, 20, 20, 0.1);
    transition: ease 300ms;
}

.item:hover {
    box-shadow: 0 0 35px rgba(20, 20, 20, 0.1);
    border-color: transparent;
}

.item-display {
    position: relative;
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
}

.item-display > .item-display-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(20, 20, 20, 0.6);
    z-index: 10;
    display: none;
    transition: ease 300ms;
}

.item-display:hover > .item-display-overlay {
    display: block;
}

.item-display-overlay > .icon-tray {
    position: absolute;
    left: 10px;
    top: 10px;
    display: block;
}

.item-display-overlay > .icon-tray > .icon {
    display: block;
    width: 32px;
    height: 32px;
    margin: 10px 4px;
    cursor: pointer;
}

.item-display > img {
    width: 100%;
    height: 100%;
    transition: ease 300ms;
}

.item-display:hover > img {
    transform: scale(1.05);
}

.item-description {
    position: relative;
    width: 100%;
    height: 80px;
    padding: 5px 10px;
}

.item-description > h4 {
    color: var(--primary-font-color-3);
}

.item-description > p {
    color: var(--primary-font-color-5);
}

.icon {
    position: relative;
    width: 16px;
    height: 16px;
}

/*Closet*/

.scroll-box {
    position: relative;
}

.scroll-btn {
    width: 15%;
    max-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(20, 20, 20, 0.3);
    z-index: 100;
    cursor: pointer;
    transition: all ease 300ms;
}

.scroll-btn:hover {
    background-color: rgba(20, 20, 20, 0.6);
}

.right-scroll-btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

.left-scroll-btn {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}

.left-scroll-btn > img {
    transform: rotate(180deg);
}

.closet-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    margin: 40px 0;
}

.closet {
    position: relative;
    height: 65vh;
    width: clamp(250px, 100%, 500px);
    min-width: 400px;
    max-width: 100%;
    margin-right: 20px;
    border: var(--primary-font-color-7) 1px solid;
    border-radius: 10px;
    overflow: hidden;
}

.closet-display {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: grey 1px solid;
}

.closet-container::-webkit-scrollbar, 
.closet-display::-webkit-scrollbar {
    display: none;
}

.closet-display > .img-container {
    position: relative;
    height: 72%;
    width: fit-content;
    margin: 0 -10vw;
    transform-style: preserve-3d;
    transform: rotateY(70deg) skew(0, 10deg);
    cursor: pointer;
    transition: all ease 300ms;
}

.closet-display > .img-container:hover {
    margin: 0 -8vw;
}

.closet-display > .img-container > img {
    height: 100%;
    width: auto;
}

/*Search*/

.search-container {
    position: relative;
    width: 100%;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.search-container > label {
    position: relative;
    width: clamp(280px, 100%, 500px);
}

.search-container > label > .icon {
    position: absolute;
    left: 10px;
    top: 10px;
}

.search-container > label > input {
    width: 100%;
}

/*Header*/

header {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: clamp(50px, 5vh, 90px);
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    border-bottom: var(--primary-font-color-7) 1px solid;
    z-index: 100;
}

header > a {
    height: 100%;
}

header > a > img {
    height: 100%;
    width: auto;
    cursor: pointer;
}

/*Tag*/

.tag-container {
    position: relative;
    justify-content: flex-start;
    width: fit-content;
    max-width: 100%;
    overflow-x: auto;
}

.tag-container::-webkit-scrollbar {
    display: none;
}

.tag {
    position: relative;
    color: var(--primary-font-color-7);
    border: var(--primary-font-color-7) 1px solid;
    border-radius: 4px;
    margin: 8px;
    padding: 6px 12px 7px;
    font-size: 13.5px;
    cursor: pointer;
    text-transform: capitalize;
    transition: 100ms ease;
}

.tag:hover, .active-tag {
    color: var(--primary-font-color-1);
    border: var(--primary-font-color-1) 1px solid;
}

/*Loader*/

.loader-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loader-case {
    position: relative;
    width: clamp(40px, 10em, 50px);
    height: clamp(40px, 10em, 50px);
}

.loader {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: rgb(224, 224, 224) 4px solid;
    border-top: rgb(59, 187, 61) 4px solid;
    animation: spin 1.2s linear infinite;
    z-index: 10;
}

.loader-container > .loading-text {
    font-size: 14px;
    color: var(--primary-font-color-4);
    width: clamp(250px, 100%, 400px);
    margin-top: 40px;
}

/*Animations*/

@keyframes spin {
    0% { 
      transform: rotate(0deg); 
    }
    100% { 
      transform: rotate(360deg); 
    }
}

@media only screen and (max-width: 640px) {
    .page-container > section {
        padding: 20px;
    }

    .page-container > section > .flex-container {
        flex-wrap: wrap;
    }

    .page-container > section > .btn-group .l-btn {    
        padding: 8px 16px;
        font-size: 16px;
        border-radius: 5px;
        border-width: 1px;
    }

    .item-grid {
        padding: 0;
    }
}


